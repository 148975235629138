import React from "react";

const smile = ({size, color}) => {
  return (
    <div style={{ width: size, height: size }}>
      <svg stroke={color} width="100%" height="100%" viewBox="0 0 673 673" xmlns="http://www.w3.org/2000/svg">
          <path fill={`inherit`} stroke-width={`20px`} d="M336.147 562.573C212.199 562.573 110.653 461.027 110.653 336.333H118.122C118.122 456.547 215.934 555.107 336.895 555.107C457.859 555.107 554.922 456.549 554.922 336.333H562.391C562.386 461.027 460.839 562.573 336.151 562.573H336.147Z" />
          <path fill={`inherit`} stroke-width={`20px`} d="M244.307 263.907C226.385 263.907 216.681 222.095 216.681 183.267C216.681 144.439 226.39 102.627 244.307 102.627C262.228 102.627 271.932 144.439 271.932 183.267C271.932 222.095 261.479 263.907 244.307 263.907ZM244.307 110.093C236.093 110.093 224.145 137.719 224.145 183.265C224.145 228.812 236.093 256.437 244.307 256.437C252.52 256.437 264.468 228.812 264.468 183.265C264.468 137.719 252.52 110.093 244.307 110.093V110.093Z" />
          <path fill={`inherit`} stroke-width={`20px`} d="M428.733 263.907C410.812 263.907 401.108 222.095 401.108 183.267C401.108 144.439 410.816 102.627 428.733 102.627C446.655 102.627 456.359 144.439 456.359 183.267C456.359 222.095 446.65 263.907 428.733 263.907ZM428.733 110.093C420.52 110.093 408.572 137.719 408.572 183.265C408.572 228.812 420.52 256.437 428.733 256.437C436.947 256.437 448.895 228.812 448.895 183.265C448.895 137.719 436.947 110.093 428.733 110.093V110.093Z" />
          <path fill={`inherit`} stroke-width={`20px`} d="M336.147 7.80266C517.587 7.80266 664.68 154.896 664.68 336.336C664.68 517.776 517.587 664.869 336.147 664.869C154.707 664.869 7.61333 517.776 7.61333 336.336C7.61333 154.896 155.453 7.80266 336.147 7.80266M336.147 0.333862C150.227 0.333862 0.146667 150.414 0.146667 336.334C0.146667 522.254 150.227 672.334 336.147 672.334C522.067 672.334 672.147 522.254 672.147 336.334C672.147 150.414 522.067 0.333862 336.147 0.333862V0.333862Z" />
      </svg>
    </div>
  )
}

export default smile;
