import React from "react";

const ExtLink = ({
    url,
    children,
    classes,
}) => {
  return (
    <a
        href={url}
        target="_blank"
        rel="noreferrer noopener noindex"
        className={`${classes} text-blue-700 hover:border-blue-700 dark:hover:border-teal-300 dark:text-teal-300 border-b border-b-soid border-transparent inline-block`}
    >
      {children}
    </a>
  )
}

export default ExtLink;
