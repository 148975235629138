import React, { useState } from "react";
import Obfuscate from 'react-obfuscate';
import { AnimatePresence, motion } from "framer-motion";
import Layout from "../components/Layout/Layout";
import ExtLink from "../components/ExtLink";
import { WorkWrapper } from "../components/Work";



const IndexPage = ({ children }) => {
  const [contactLinks, setContactLinks] = useState(false);

  const variants = {
    open: { 
      opacity: 1,
      x: 0 
    },
    closed: { 
      opacity: 0, 
      x: "-2%" 
    },
  }

  return (
    <>
      <Layout>
        <div className="max-w-5xl pt-16 md:pt-24 mx-auto px-5 md:px-8 tracking-wide">
            <div>
              <p className="leading-8 md:text-lg md:leading-10 dark:text-white">
                  Jay Werra is Los Angeles based Creative Developer & Designer.
                  He is currently working as a Senior Front End Developer at <ExtLink url={`https://crowdstrike.com`}>CrowdStrike</ExtLink> and 
                  operates independently as <ExtLink url={`https://werra.io`}>Werra LLC</ExtLink>.
              </p>
            </div>
            <WorkWrapper />
            <div className="md:flex items-center mt-12 py-12">
                <button 
                  onClick={() => setContactLinks(!contactLinks)}
                  className="text-black dark:text-white text-base"
                >
                  Contact Info
                </button>
                <AnimatePresence initial={false}>
                  <motion.ul 
                    className={`${contactLinks ? "block md:flex" : "hidden"} mt-6 md:mt-0 md:ml-6`}
                    animate={contactLinks ? "open" : "closed"}
                    variants={variants}
                  >
                    <li className="mr-6 text-base">
                      <ExtLink
                        url="https://werra.io"
                        className="text-blue-700 hover:border-blue-700 dark:hover:border-teal-300 dark:text-teal-300 border-b border-b-soid border-transparent inline-block text-base"
                      >
                        Werra, LLC
                      </ExtLink>
                    </li>
                    <li className="mr-6 text-base">
                      <ExtLink 
                        url="https://github.com/jaywerra"
                        classes={`text-black hover:text-blue-700`}
                      >
                        Github
                      </ExtLink>
                    </li>
                    <li className="mr-6 text-base">
                      <ExtLink
                        url="https://www.linkedin.com/in/jaywerra/"
                        classes={`text-black hover:text-blue-700`}
                      >
                        Linkedin
                      </ExtLink>
                    </li>
                    <li>
                    <Obfuscate
                        email="jay@jaywerra.com"
                        headers={{
                          subject: 'Website Inquiry',
                        }}
                        className="mr-6 text-blue-700 hover:border-blue-700 dark:hover:border-teal-300 dark:text-teal-300 border-b border-b-soid border-transparent inline-block text-base"
                      />
                    </li>
                  </motion.ul>
                </AnimatePresence>
              </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage;

export const Head = () => (
  <>
    <title>Jay Werra | {new Date().getFullYear()} Selected Works</title>
    <meta name="description" content="Portfolio of Los Angeles based Front End Developer, Jay Werra." />
  </>
)
