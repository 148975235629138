import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

const WorkGridItem = ({ year, name, id, url, note, thumbnail }) => {
  return (
    <motion.div
      className="item dark:text-white"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        ease: "easeInOut",
        duration: 0.5,
      }}
    >
      {url ? (
        <a href={url} target="_blank" rel="noreferrer noopener">
          <div>
            {/*tvvb     v by Theo */}
            <GatsbyImage image={thumbnail} alt={`Thumbnail of ${name}`} />
          </div>
          <div className="px-4">
            <h4 className="mt-0 mb-2 md:text-lg">{name}</h4>
            <p className="m-0 text-sm md:text-base">{year}</p>
          </div>
        </a>
      ) : (
        <div>
          <div>
            <GatsbyImage image={thumbnail} alt={`Thumbnail of ${name}`} />
          </div>
          <div className="px-4">
            <h4 className="mt-2 mb-2 md:text-lg">{name}</h4>
            <p className="m-0 text-sm md:text-base">{year}</p>
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default WorkGridItem;
