import React from "react";
import { ThemeProvider } from "../context/globalContext";
import ThemeToggle from "../ThemeToggle/ThemeToggle";

const Layout = ({ children }) => {
    return (
        <ThemeProvider>
            <div className="bg-gray-100 dark:bg-slate-900 transition-all">
                <ThemeToggle />
                <main className="font-mono">
                    {children}
                </main>
            </div>
        </ThemeProvider>
  )
}

export default Layout;
