import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { AnimatePresence } from "framer-motion";
import WorkGridItem from "../WorkGridItem";
import WorkListItem from "../WorkListItem";

const WorkWrapper = () => {
  const [toggleGrid, setToggleGrid] = useState(true);

  const projects = useStaticQuery(graphql`
    {
      allProjectsJson {
        nodes {
          projectName
          projectThumbnail {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP, AVIF]
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          projectYear
          url
          note
        }
      }
    }
  `);

  return (
    <>
      <div className="flex justify-between items-center my-12 lg:my-16">
        <h2 className="my-0 text-blue-700 dark:text-teal-300 md:text-xl">
          Selected Work
        </h2>
        <ul className="flex text-sm md:text-lg">
          <li>
            <button
              onClick={() => setToggleGrid(true)}
              className={`${
                toggleGrid
                  ? "text-blue-700 dark:text-teal-300"
                  : "text-black dark:text-white"
              }`}
            >
              Grid
            </button>
          </li>
          <li>
            <span className="inline-block mx-2 dark:text-white">/</span>
          </li>
          <li>
            <button
              onClick={() => setToggleGrid(false)}
              className={`${
                !toggleGrid
                  ? "text-blue-700 dark:text-teal-300"
                  : "text-black dark:text-white"
              }`}
            >
              List
            </button>
          </li>
        </ul>
      </div>
      <AnimatePresence initial={true}>
        {projects.allProjectsJson.nodes && (
          <div
            className={`${
              toggleGrid
                ? "grid md:grid-cols-2 gap-6 md:gap-8"
                : "flex flex-col"
            }`}
          >
            {projects.allProjectsJson.nodes.map((proj) => {
              return (
                <React.Fragment key={proj?.projectName}>
                  {toggleGrid ? (
                    <WorkGridItem
                      name={proj?.projectName}
                      year={proj?.projectYear}
                      url={proj?.url}
                      note={proj?.note}
                      thumbnail={
                        proj?.projectThumbnail?.childImageSharp.gatsbyImageData
                      }
                    />
                  ) : (
                    <WorkListItem
                      name={proj?.projectName}
                      year={proj?.projectYear}
                      url={proj?.url}
                      note={proj?.note}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

export default WorkWrapper;
