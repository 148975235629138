import React, { useContext } from "react";
import { ThemeContext } from "../context/globalContext";
import { SmileIcon } from "../../icons";

const ThemeToggle = () => {
    const { theme, setTheme } = useContext(ThemeContext);

    return (
        <div className="absolute right-2 top-2 md:right-4 md:top-4">
            <button
                onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
                className="text-2xl md:text-3xl"
            >
                <span>
                    {theme === "dark" ? 
                        <SmileIcon size={`20px`} color={`#5eead4`} />
                    :
                        <SmileIcon size={`20px`} color={`#1d4ed8`} />
                    }
                </span>
            </button>
        </div>
    )
}

export default ThemeToggle;
