import React from "react";
import { motion } from "framer-motion";

const WorkListItem = ({ year, name, url, note }) => {
  return (
    <motion.div
      className="flex justify-between border-t border-t-solid border-black dark:border-white dark:text-white first:border-t-0 last:border-b-0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        ease: "easeInOut",
        duration: 0.5,
      }}
    >
      {url ? (
        <a
          href={url}
          target="_blank"
          rel="noreferrer noopener"
          className="flex justify-between items-center w-full py-4 md:py-6"
        >
          <h4 className="md:text-lg">{name}</h4>
          <p>{year}</p>
        </a>
      ) : (
        <div className="flex justify-between items-center w-full py-4 md:py-6">
          <h4 className="md:text-lg">
            {name} {note && <span>/ {note}</span>}
          </h4>
          <p>{year}</p>
        </div>
      )}
    </motion.div>
  );
};

export default WorkListItem;
